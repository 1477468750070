import { React } from "react";

let TicketBaseForm = (props) => {
    const baseType = props.ticket.InterconnectionType;
    return (baseType?.length > 0 ?
        <>
            <div className="grid-row grid-gap">
                {baseType === "Reports" || baseType === "Power BI" ?
                    <>
                        <div className="grid-col-6 usa-form-group">
                            <label htmlFor="ReportRequestType" className="usa-label">Identify the type of issue or request.<abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                            <select value={props.ticket.ReportRequestType || ""} name="ReportRequestType" onChange={props.changeHandler} required className="usa-select ReportRequestType" id="ReportRequestType">
                                <option value="">Select Issue or Request Type</option>
                                {props.ReportList?.map(option => (
                                    <option key={option.text} value={option.text}>
                                        {option.text}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <div className="grid-col-6 usa-form-group">
                            <label htmlFor="ReportRequest" className="usa-label">Specify what the issue or request is.<abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                            <select value={props.ticket.ReportRequest || ""} name="ReportRequest" onChange={props.changeHandler} required className="usa-select ReportRequest" id="ReportRequest">
                                <option value="">Select Issue or Request</option>
                                {props.ReportTypes?.map(option => (
                                    <option key={option.text} value={option.text}>
                                        {option.text}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <div className="grid-col-6 usa-form-group">
                            <label htmlFor="AgencyInfo" className="usa-label">Provide your specific Office Name</label>
                            <input value={props.ticket.AgencyInfo || ""} onChange={props.changeHandler} name="AgencyInfo" placeholder="Office Name" className="usa-input" id="AgencyInfo" />
                        </div>

                        <div className="grid-col-6 usa-form-group">
                            <label htmlFor="Tenant" className="usa-label">
                                Tenant
                            </label>
                            <select value={props.ticket.Tenant || ""} placeholder="Select Tenant" name="Tenant" onChange={props.changeHandler} className="usa-select Tenant" id="Tenant">
                                <option value="">Select Tenant</option>
                                {props.Tenants?.map(option => (
                                    <option key={option.text} value={option.text}>
                                        {option.text}
                                    </option>
                                ))}

                            </select>

                        </div>

                    </>
                    :
                    <>
                        <div className="grid-col-6 usa-form-group">
                            <label htmlFor="InterconnectedSystem" className="usa-label">What system(s) is being used to interconnect with USA Staffing?</label>
                            <input value={props.ticket.InterconnectedSystem || ""} name="InterconnectedSystem" onChange={props.changeHandler} placeholder="Interconnected System" className="usa-input" id="InterconnectedSystem" />
                        </div>

                        <div className="grid-col-6 usa-form-group">
                            <label htmlFor="AgencyInfo" className="usa-label">Provide your specific agency information, such as Tenant, Organization, or Office</label>
                            <input value={props.ticket.AgencyInfo || ""} onChange={props.changeHandler} name="AgencyInfo" placeholder="Agency Info" className="usa-input" id="AgencyInfo" />
                        </div>

                    </>
                }


            </div>
            <div className="usa-form-group">
                <label htmlFor="Description" className="usa-label usa-help-maxwidth">Please enter a detailed description of the issue, including information such as report path, error message, steps taken when the issue was encountered, what you expected to happen versus what actually happened, impact of the issue, etc. After the ticket is created, please respond with screenshots (do not include PII) if applicable.<abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                <textarea spellCheck="true" required placeholder="Description" onChange={props.changeHandler} value={props.ticket.Description || ""} name="Description" className="usa-textarea usa-input" id="Description"></textarea>
            </div>
        </>
        :
        <></>
    )
};


export { TicketBaseForm };