import React, { useState } from "react"
import { format } from 'react-string-format'
import { AiFillCloseCircle } from "react-icons/ai"
import { PaginatedList } from "../react-paginated-list"
//import { PaginatedList } from "../paginated-list";
import {
    Container,
    Row,
    Col,
    Card, CardTitle
} from "ui-library";
import { IconButton } from './IconComponent';

let FoundResults = (props) => {
    /**
     * React lifecycle method that will inject the data into the state.
     */

    const { searchResults } = props
    const [foundCount, setFoundCount] = useState(searchResults.length);
    const [filteredResults, setFilteredResults] = useState(searchResults);
    const [filterWord, setFilterWord] = useState("All");



    //handles filter selections
    const filterResults = (e) => {

        const queryResult = e.target.value === "All" ? searchResults : searchResults.filter(function (item) {
            return item.Filter === e.target.value
        });

        setFilterWord(e.target.value);
        setFilteredResults(queryResult);
        setFoundCount(queryResult.length);

    }

    //jumps to the home page
    const clearSearch = (e) => {
        location.href = './'
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const displayName = foundCount > 0 ? format('Results ({0} found)', foundCount) : "No Results";


    //creates the search filters out the found results
    const counts = searchResults.reduce((p, c) => {
        var name = c.Filter;
        if (!p.hasOwnProperty(name)) {
            p[name] = 0;
        }
        p[name]++;
        return p;
    }, {});

    const countsExtended = Object.keys(counts).map(k => {
        return { Name: k, Count: counts[k], ID: k.replace(/ /g, '') };
    });
    countsExtended.unshift({ Name: "All", Count: searchResults.length, ID: "all" });
    countsExtended.sort()

    //scrolls to top when user flips through result pages
    const pageChanged = () => {
        window.scrollTo(0, 0)
    };

    return (
        <>
            <Container>

                {foundCount > 0 ?


                    <Row>
                        <div className="grid-col-3 text-left" style={{ float: "left" }}>
                            <span style={{ marginLeft: "1rem", fontSize: "1.2rem", fontWeight: "900" }} className="opm-textColor-navy">Filters</span>

                        </div>
                        <div className="grid-col-9" style={{ float: "right" }}>
                            <div className="col-md-6 text-left" style={{ float: "left" }}>
                                <span className="opm-textColor-navy  found-result-title">{displayName}</span>

                            </div>

                            <div className="col-md-6 text-right" style={{ float: "right" }}>
                                <button type="button" id="ClearSearch"
                                    onClick={clearSearch} data-toggle="tooltip" data-placement="top"
                                    title="Clear Results">Clear Results <AiFillCloseCircle color="rgb(115, 117, 120)" size="28" /></button>

                            </div>
                        </div>

                        <Col colClass="grid-col-3">
                            <div style={{ height: "auto" }}>
                                <Card autoWidth={true}>
                                    <div style={{
                                        fontSize: "1em",
                                        textAlign: "left",
                                        padding: "10px"
                                    }}>

                                        <form onSubmit={handleSubmit}>
                                            <div style={{ margin: "0 auto", fontSize: "18px" }}>
                                                <span style={{ fontSize: "1rem", fontWeight: "900" }} className="opm-textColor-navy">Page Content</span>

                                                <br />

                                                <fieldset style={{ border: "none", padding: "5px 0 0 0" }}>

                                                    {countsExtended.map((arr) => {
                                                        return (
                                                            <div className="usa-filter-button" >
                                                                <div className="col-12" style={{ display: "flex" }}>
                                                                    <input type="radio" className="radio-dot" id={arr.ID} onChange={filterResults}
                                                                        checked={arr.Name == filterWord} name="filter" value={arr.Name} />
                                                                    <label className="usa-filter-button-label"
                                                                        style={{ fontWeight: arr.Name == filterWord ? "bold" : "normal" }}
                                                                        for={arr.ID}>{arr.Name} </label>

                                                                    <button type="button" id="filterResult" value={arr.Name} onClick={filterResults}
                                                                        style={{
                                                                            backgroundColor: arr.Name == filterWord ? '#0078d4' : '#e6e6e6',
                                                                            fontWeight: arr.Name == filterWord ? "bold" : "normal",
                                                                            color: arr.Name == filterWord ? "white" : "#1b1b1b"
                                                                        }}
                                                                        data-toggle="tooltip" data-placement="top" title={arr.Count}>{arr.Count}</button>


                                                                </div>
                                                            </div>)
                                                            ;
                                                    })}

                                                </fieldset>
                                            </div>
                                        </form>
                                    </div>
                                </Card>
                            </div>
                        </Col>
                        <Col colClass="grid-col-9">
                            <Card autoWidth={true}>
                                <PaginatedList
                                    list={filteredResults}
                                    itemsPerPage={20}
                                    onPageChange={pageChanged}
                                    renderList={(list) => (
                                        <>
                                            {list.map((item, id) => {
                                                return (
                                                    <IconButton item={item} subTitleSize={120} />
                                                );
                                            })}
                                        </>
                                    )}
                                />
                            </Card>
                        </Col>
                    </Row> :
                    <Row>
                        <h2 className="opm-textColor-navy search-title">{displayName}</h2>
                        <br />
                        <br />

                        <Col colClass="grid-col-12">
                            <CardTitle title={"Sorry, we couldn't find anything that fit your search."} alignment="center" />
                            <br /><br />

                            <div class="no-result__circle">
                                <svg class="no-result-icon" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                    <defs>
                                        <linearGradient id="opm-gradient">
                                            <stop offset="0%" stop-color="#0a3553" />
                                            <stop offset="100%" stop-color="#D13138" />
                                        </linearGradient>
                                    </defs>
                                    
                                </svg>
                            </div>
                        </Col>
                    </Row>
                }

            </Container>

        </>
    )

};


export default FoundResults