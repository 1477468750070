import React from "react";
import {
    CardTitle,
    CardBody,
  } from "ui-library";
  
let TaskUpdateAPI = () => {
    //load text markup from json
    let json = require('./TaskUpdateAPI.json');
    const { OverviewTitle, OverviewMainText,
        ListText, ListLabels, SecondaryText, TertiaryText, TertiaryTextUrl, TertiaryTextUrlLabel
    } = json[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {OverviewMainText}
                    </p>
                    <p>
                        {ListText}
                        <br /><br />
                        <ol>                        
                            {ListLabels.map((item)=> {
                                return <li>{item.Label}</li>
                            })}                    
                        </ol>
                    </p>
                </p>
                <p>
                    <p>
                        {SecondaryText}
                    </p>
                    <p dangerouslySetInnerHTML={ {__html: TertiaryText}}/>
                    <p>
                        <a href={TertiaryTextUrl} target="_blank">{TertiaryTextUrlLabel}</a>
                    </p>
                </p>
            </CardBody>
        </>
        
    )
}
export { TaskUpdateAPI };