import React from "react";
import {
    CardTitle,
    CardBody, Container, Row
} from "ui-library";
import { IconButton } from "../IconComponent";
import { VideoTabletCol } from '../Utils.js'


let InterconnDocs = (props) => {

    const { MainTitle, MainText, MainLinks } = require(`${props.ContentFile}`)[0];
    var vids = [];
    var otherLinks = [];

    MainLinks.map((obj) => {
        if (obj.Extension === "mp4") {
            vids = vids.concat(obj);
        } else {
            otherLinks = otherLinks.concat(obj);
        }
    });

    return (
        <>
            <CardTitle title={MainTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {MainText}
                    </p>

                    {vids.length > 0 ? <>
                        <div style={{ textAlign: "center" }}>

                        <Container>
                            <Row>
                                {vids.map((obj) => {
                                    return (<VideoTabletCol url={obj.URL} name={obj.Label} />);
                                })}
                            </Row>
                            </Container>
                        </div>

                        <br />

                    </> : <>

                    </>}
                    <br/>
                    <p>
                        {otherLinks.map((link) => {
                            return <IconButton item={link} cssClass={'actionIcon'} iconSize={22}/>;
                        })}
                    </p>
                </p>

            </CardBody>
        </>

    )
}

export { InterconnDocs };