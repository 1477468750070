import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";


let InterconnPage = (props) => {

    const { OverviewTitle, OverviewMainText, OverviewSecondaryText } = require(`${props.ContentFile}`)[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {OverviewMainText}
                    </p>
                </p>

                <p>
                    <p>
                        {OverviewSecondaryText}
                    </p>
                </p>


            </CardBody>
        </>

    )
}

export { InterconnPage };