import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";

let AuthorForum = () => {

    let authorforum = require('./AuthorForum.json');
    const { OverviewTitle, OverviewText } = authorforum[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {OverviewText}
                    </p>
                </p>
            </CardBody>
        </>

    )
}

export { AuthorForum };