import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";

let PowerBIConsumerForum = () => {

    let consumerforum = require('./ConsumerForum.json');
    const { OverviewTitle, OverviewText } = consumerforum[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    {<p dangerouslySetInnerHTML={{ __html: OverviewText }} />}
                </p>
            </CardBody>
        </>

    )
}

export { PowerBIConsumerForum };