import React, { Component, useState } from "react";

import { Container, Row, Col, CardTitle, CardAction } from 'ui-library'

export class PageNotFound extends Component {


    render() {
        var displayName = "Page Not Found";
        var subMessage = "We're sorry, we can't find the page you're looking for. The site administrator may have removed it, changed its location, or made it otherwise unavailable.";


        return (
            <div className="text-center">
                <br />
                <h2 className="opm-textColor-navy">{displayName}</h2>
                <Container>
                    <Row>
                        <Col colClass='grid-col'>
                            <CardTitle title={subMessage} alignment="center" />
                            <br />
                            <br />
                            <p className='subheading'>If you typed the URL directly, check your spelling and capitalization. Our URLs look like this: <strong> <a href={'/Help/FAQs'}>https://developer.usastaffing.gov/Help/FAQs</a></strong>.</p>
                            <p className='subheading'>Visit our homepage for helpful tools and resources, or contact us and we'll point you in the right direction.</p>
                            <Container>
                                <Row>
                                    <Col colClass='grid-col'>
                                        <CardAction title={'Visit Homepage'} url={'/'} />

                                    </Col>
                                    <Col colClass='grid-col'>
                                        <CardAction title={'Contact Us'} url={'/Help/SubmitTicket'} />

                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
            </div>
        );
    }

}

