import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { IconButton } from '../../../IconComponent';

let ReportAuthor = () => {

    let jobaids = require('./ReportAuthor.json');
    const { AuthorOverviewTitle, AuthorOverviewText, AuthorLinks } = jobaids[0];

    return (
        <>
            <CardTitle title={AuthorOverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    {AuthorOverviewText}
                </p>
            </CardBody>
        </>

    )
}

export { ReportAuthor };