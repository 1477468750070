import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { IconButton } from '../../../IconComponent';


let AFDJobAids = () => {

    let jobaids = require('./ApplicantFlowAids.json');
    const { OverviewTitle, OverviewText, JobAidsLinks } = jobaids[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {OverviewText}
                    </p>
                    <p>
                        {JobAidsLinks.map((link) => {
                            return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28}/>;
                        })}
                    </p>
                </p>

            </CardBody>
        </>

    )
}

export { AFDJobAids };