import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";

let WorkgroupPages = (props) => {

    let content = require(`${props.ContentFile}`);
    const { OverviewTitle, OverviewMainText } = content[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    {OverviewMainText}
                </p>
            </CardBody>
        </>

    )
}

export { WorkgroupPages };