import React, { useState, useEffect } from "react";
import { DataEntityTableView, FilteredDataEntityTableView } from "./DataEntityTableView";
import { DropDownFilter } from "./Components/DropDownFilter";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SearchFilter } from "./Components/SearchFilter";

let CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{margin:0}}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3, padding:'0px' }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function props(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

let DataDictionaryTabs = (entityProps) => {
    const [value, setValue] = useState(0);
    const [currentEntity, setCurrentEntity] = useState(entityProps.Entity??"");
    const [entityDatas, setEntityDatas] = useState([]);
    const [filteredEntityDatas, setFilteredEntityDatas] = useState([]);
    const [keywordList, setKeywords] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const entityDataJson = require.context('../../../../../public/dataentities/', false, /\.(json)$/)
        .keys().map(entity => {
            return require(`../../../../../public/dataentities/${entity.slice(2)}`);

        });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        
        setLoading((newValue == 0 && currentEntity == "") || (newValue == 1 && keywordList.length == 0));
    };

    let dropDownChanged = (entityName) => {
        setCurrentEntity(entityName);
        getEntityDatas(entityName);
    };
    
    let searchFilterChanged = (keywords) => {

        setKeywords(keywords);
        setLoading(true);

        var data = [];
        
        var flattenedEntityDatas = entityDataJson
            .map(entity => {
                return entity.DataEntityFields.map(field => {
                    return { ...field, DataEntityField: entity.Name };
                })
            })
            .flat();

        {keywords.map((keyword) => {
            
            var filteredData = flattenedEntityDatas.filter((entity)=> entity.Description.toLowerCase().includes(keyword.toLowerCase()) || 
                                                                      entity.Name.toLowerCase().includes(keyword.toLowerCase()));

            if(filteredData.length > 0)
            {
                filteredData.forEach(i => i.Type = translateType(i.Type));

                filteredData.map((item) => {

                    var match = data.filter((e)=>e.Name == item.Name && e.Description == item.Description && e.DataEntityField == item.DataEntityField)
                    
                    if(match.length == 0)
                    {
                        data.push(item);
                        console.log(item);
                    }
                })
            }
        })}

        setFilteredEntityDatas(data);
        setLoading(keywords.length == 0);
    };

    function translateType(type) {
        const convt = ["array","boolean", "boolean (required)", "datetime", "datetime (required)", "double", "double (required)", "int", "int (required)", "long", "long (required)", "object", "string", "string (required)", "decimal", "decimal (required)"];
        return convt[type];
    };

    const getEntityDatas = async (entityName) => {
        setLoading(true);
        const res = await fetch(`/DataEntities/${entityName}.json`);
        const data = await res.json();
        data.DataEntityFields.forEach(i => i.Type = translateType(i.Type));
        console.log(data);
        var flattenedEntityDatas = data.DataEntityFields.map(field => {
            return { ...field, DataEntityField: data.Name };
        })
        .flat();
        setEntityDatas(flattenedEntityDatas);
        setLoading(false);
    };


    useEffect(() => {

        if (currentEntity.length > 0) { dropDownChanged(currentEntity); }

    }, []);



    return (

        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{
                    style: {
                        backgroundColor: "#005999", height: '3px'
                    }
                }}>
                    <Tab label="API Entity" {...props(0)} />
                    <Tab label="Keywords" {...props(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div>
                    <div style={{ maxWidth: '450px' }}>
                        <DropDownFilter label={"Select an entity"} val={currentEntity} list={entityDataJson} onChange={dropDownChanged} />
                    </div>
                    <br />
                    {!loading ? 
                        <div>
                            <h5><b>Results ({entityDatas.length})</b></h5>
                            <DataEntityTableView data={entityDatas} />
                        </div> : 
                        <div><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>}
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div>
                    <div style={{ maxWidth: '50%' }}>
                        <SearchFilter label={"Keywords"} placeholder={"Add one or more keywords"} list={entityDataJson} keywords={keywordList} onChange={searchFilterChanged} />
                    </div>
                    <br />
                    {!loading ? 
                        <div>
                            <h5><b>Results ({filteredEntityDatas.length})</b></h5>
                            <DataEntityTableView data={filteredEntityDatas} />
                        </div> : 
                        <div><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>}
                        
                </div>
            </CustomTabPanel>
        </Box>
    );
}

export { DataDictionaryTabs };