import React from "react";
import {
    Card,
    CardAction,
    CardTitle,
    CardBody,
    TabletCol,
    Container,
    Row
} from "ui-library";

let Workgroups = () => {

    //load text markup from json
    let workgroup = require('./Workgroups.json');
    const { WGOverviewTitle, WGOverviewMainText, WGOverviewSecondaryText } = workgroup[0];

    let wgPages = [
        ...require('./ApplicantFlow/ApplicantFlow.json') ?? [],
        ...require('./ReportAnalytics/ReportAnalytics.json') ?? [],
        ...require('./Interconnections.json') ?? []
    ].map(list => {

        return {
            OverviewTitle: list.OverviewTitle,
            CardOverviewText: list.CardOverviewText,
            CardActionText: list.CardActionText,
            PageURL: list.PageURL
        }

    });


    return (
        <>
            <CardTitle title={WGOverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {WGOverviewMainText}
                    </p>
                    <p>
                        {WGOverviewSecondaryText}
                    </p>
                </p>

                <div>
                    <TabletCol>
                        <Container>
                            <Row>
                                {wgPages.map(item => {
                                    return (
                                        <TabletCol>
                                            <Card autoWidth={false}>
                                                <CardTitle title={item.OverviewTitle} />
                                                <CardBody>
                                                    {item.CardOverviewText}
                                                </CardBody>
                                                <CardAction title={item.CardActionText} url={item.PageURL} />
                                            </Card>
                                        </TabletCol>
                                    )
                                })}
                            </Row>
                        </Container>
                    </TabletCol>
                </div>


            </CardBody>
        </>

    )
}


export { Workgroups };