import React, { useState, useEffect } from "react"
import * as JsSearch from "js-search"
import FoundResults from './FoundResults';

let ClientSearch = (props) => {
    /**
     * React lifecycle method that will inject the data into the state.
     */

    const { engine } = props
    const { contents } = props
    const searchWord = props.searchQuery

    const [prevState, setPrevState] = useState(null);

    const [isLoading, setLoading] = useState(true);
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState(null);
    const [indexByLabel, setIndexByLabel] = useState(false);
    const [indexBySubTitle, setIndexBySubTitle] = useState(false);
    const [indexByURL, setIndexByURL] = useState(false);
    const [termFrequency, setTermFrequency] = useState(true);
    const [removeStopWords, setRemoveStopWords] = useState(false);
    const [selectedStrategy, setSelectedStrategy] = useState("");
    const [selectedSanitizer, setSelectedSanitizer] = useState("");


    if (props !== prevState) {
        setIndexByLabel(engine.LabelIndex);
        setIndexBySubTitle(engine.SubTitleIndex);
        setIndexByURL(engine.URLIndex);
        setTermFrequency(engine.SearchByTerm);
        setSelectedSanitizer(engine.searchSanitizer);
        setSelectedStrategy(engine.indexStrategy);
        setPrevState(props)
    }

    useEffect(() => {

        rebuildIndex(contents, searchWord);
    }, []);


    /**
     * rebuilds the overall index based on the options
     */
    const rebuildIndex = (contents, searchQuery) => {

        const dataToSearch = new JsSearch.Search("Label")

        if (removeStopWords) {
            dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
                dataToSearch.tokenizer
            )
        }
        /**
         * defines an indexing strategy for the data
         * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
         */
        if (selectedStrategy === "All") {
            dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
        }
        if (selectedStrategy === "Exact match") {
            dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
        }
        if (selectedStrategy === "Prefix match") {
            dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
        }

        /**
         * defines the sanitizer for the search
         * to prevent some of the words from being excluded
         */
        selectedSanitizer === "Case Sensitive"
            ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
            : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
        termFrequency === true
            ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("Label"))
            : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

        // sets the index attribute for the data
        if (indexByLabel) {
            dataToSearch.addIndex("Label")
        }
        if (indexBySubTitle) {
            dataToSearch.addIndex("SubTitle")
        }
        if (indexByURL) {
            dataToSearch.addIndex("URL")
        }

        dataToSearch.addDocuments(contents) // adds the data to be searched
        setSearch(dataToSearch);
        setLoading(false);

        /**
         * handles the input change and perform a search with js-search
         * in which the results will be added to the state
         */
        const queryResult = searchQuery.length > 0 ? dataToSearch.search(searchQuery) : contents;
        setSearchResults(queryResult);

    }


    return (
        <>

            <div className="text-center">
                {isLoading ? <div></div> :
                    <FoundResults searchResults={searchResults} />}

                <br />
                <br />
            </div>
        </>
    )

};


export default ClientSearch