import React from "react";
import { format } from 'react-string-format';
import {
    Container,
    Row,
    CardTitle,
    CardBody,
    Accordion,
    AccordionSection,
    TabletCol
  } from "ui-library";
import { VideoTabletCol } from '../../Utils.js'

let GettingStarted = () => {


    //load text markup from json
    let json = require('./GettingStarted.json');
    const { title, OverviewMainText, OverviewSecondaryText,
      OverviewSecondaryLinkText, OverviewSecondaryLinkUrl, 
      OverviewTertiaryText, AccordionTitleImageSource, 
      AccordionStepOneTitle, AccordionStepOneText,
      AccordionStepTwoTitle, AccordionStepTwoText, 
      AccordionStepTwoLinkText, AccordionStepTwoLinkUrl,
      AccordionStepTwoSecondaryText, AccordionStepTwoTertiaryText,
      AccordionStepTwoStepsTitle, AccordionStepTwoList,
      AccordionStepThreeTitle, AccordionStepThreeText, 
      AccordionStepThreeLinkText, AccordionStepThreeLinkUrl,
      AccordionStepThreeSecondaryText, AccordionStepThreeTertiaryText,
      AccordionStepThreeTertiaryTitle, AccordionStepThreeSecondaryLinkText, 
      AccordionStepThreeSecondaryLinkUrl, AccordionStepThreeTertiaryLinkText, 
        AccordionStepThreeTertiaryLinkUrl, AccordionVideoLinks
    } = json[0];
    return (
        <>
            <CardTitle title={title} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                    {OverviewMainText}
                    </p>
                    <p>
                    {format(OverviewSecondaryText, <a href={OverviewSecondaryLinkUrl} target="_blank">{OverviewSecondaryLinkText}</a>)}
                    </p>
                    <p>
                    {OverviewTertiaryText}
                    </p>
                </p>
                <Accordion>
                <AccordionSection id="gs1" title={AccordionStepOneTitle} titleImageSource={AccordionTitleImageSource}>
                    <div>
                    <p>
                        {AccordionStepOneText}
                    </p>
                    <div style={{ textAlign: "center" }}>
                        <Container >
                          <Row>
                                        {AccordionVideoLinks.map((obj)=>{
                              return ( <VideoTabletCol url={obj.URL} name={obj.ColumnName} /> );
                              })}
                          </Row>
                        </Container>
                    </div>
                    <br />
                    <br />
                    </div>
                </AccordionSection>
                <AccordionSection id="gs2" title={AccordionStepTwoTitle} titleImageSource={AccordionTitleImageSource} >
                    <div>
                    <p> {AccordionStepTwoText} </p>
                    <p> {format(AccordionStepTwoSecondaryText, <a href={AccordionStepTwoLinkUrl} target="_blank">{AccordionStepTwoLinkText}</a>)} </p>
                    <p> {AccordionStepTwoTertiaryText} </p>
                    <p> <b>{AccordionStepTwoStepsTitle}</b> </p>
                    <ol className="interconnections">
                        {AccordionStepTwoList.map((arr)=> {
                        return <li>{arr.item}</li>;
                        })}
                    </ol>
                    </div>
                </AccordionSection>
                <AccordionSection id="gs3" title={AccordionStepThreeTitle} titleImageSource={AccordionTitleImageSource}>
                    <div>
                    <p> {format(AccordionStepThreeText, <a href={AccordionStepThreeLinkUrl} target="_blank">{AccordionStepThreeLinkText}</a>)} </p>
                    <p style={{ textAlign: "center" }}>
                        <br />
                        <img src="/img/interconnections_step3_implementation_map.png" alt="Step 3 - Interconnections - Map" />
                        <br />
                        <br />
                    </p>
                    <p>
                        {format(AccordionStepThreeSecondaryText, <a href={AccordionStepThreeSecondaryLinkUrl} target="_blank">{AccordionStepThreeSecondaryLinkText}</a>)} 
                    </p>
                    <br />
                    <h5>{AccordionStepThreeTertiaryTitle}</h5>
                    <p>
                        {format(AccordionStepThreeTertiaryText, <a href={AccordionStepThreeTertiaryLinkUrl} target="_blank">{AccordionStepThreeTertiaryLinkText}</a>)} 
                    </p>
                    </div>
                </AccordionSection>
                </Accordion>
            </CardBody>
        </>
        
    )
}
export { GettingStarted };