import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { IconButton } from '../../../IconComponent';

let ConsumerForum = () => {

    let consumerforum = require('./ConsumerForum.json');
    const { OverviewTitle, OverviewText, TrainingLinks, SecondaryText } = consumerforum[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    {<p dangerouslySetInnerHTML={ {__html: OverviewText } }/>}
                </p>
                <p>
                    {<p dangerouslySetInnerHTML={ {__html: SecondaryText } }/>}
                </p>
                <p>
                    {TrainingLinks.map((link) => {
                        return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28}/>;
                    })}
                    </p>
            </CardBody>
        </>

    )
}

export { ConsumerForum };