import React, { useState, useEffect } from 'react'
import {TextField, Autocomplete } from "@mui/material";



let DropDownFilter = ({ label, val = "", list, onChange }) => {
    const sortedList = list.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
    const [value, setValue] = useState(val ? sortedList[sortedList.findIndex(p=>p.Name == val)] : sortedList[-1]);
    const [inputValue, setInputValue] = useState(val);

    const onTagsChange = (event, values) => {
        
        if(values)
        {
            setValue(values);
            onChange(values.Name);
            console.log(values.Name);
        }
        else
        {
            setValue(null);
            onChange("");
        }
    };

    return (
        <>
            <Autocomplete
                options={sortedList}
                getOptionLabel={option => option.Name}
                onChange={onTagsChange}
                value={value}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={label}
                        margin="normal"
                    />
                )}
            />
        </>
    );
};

export { DropDownFilter };
