import { React } from "react";
import {
    Card,
    CardAction,
    CardTitle,
    CardBody,
    TabletCol,
    Container,
    Row
} from "ui-library";



let FAQs = () => {

    //load text markup from json
    let faqs = require('./FAQs.json');
    const { OverviewTitle, OverviewMainText } = faqs[0];

    let faqPages = [
        ...require('./DataAPIs.json') ?? [],
        ...require('./NHIFAQs.json') ?? [],
        ...require('./RPIFAQs.json') ?? [],
        //...require('./PowerBIFAQs.json') ?? [],
        //...require('./AuthenticationFAQs.json') ?? []

    ].map(list => {

        return {
            OverviewTitle: list.OverviewCardTitle,
            CardOverviewText: list.CardOverviewText,
            CardActionText: list.CardActionText,
            PageURL: list.PageURL
        }

    });


    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {OverviewMainText}
                    </p>
                </p>

                <div>
                    <TabletCol>
                        <Container>
                            <Row>
                                {faqPages.map(item => {
                                    return (
                                        <TabletCol>
                                            <Card autoWidth={false}>
                                                <CardTitle title={item.OverviewTitle} />
                                                <CardBody>
                                                    {item.CardOverviewText}
                                                </CardBody>
                                                <CardAction title={item.CardActionText} url={item.PageURL} />
                                            </Card>
                                        </TabletCol>
                                    )
                                })}
                            </Row>
                        </Container>
                    </TabletCol>
                </div>


            </CardBody>
        </>

    )
}

export { FAQs };