export const msalConfig = {
    auth: {
        clientId: '46ff21ac-8ed2-4c6f-9e5b-3d1e9da00b9e', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/3ce07c0b-30c5-4bcd-ad92-238975b649af',// b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes:[ 'user.read', 'email', 'profile', 'openid']
};