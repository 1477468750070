import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { IconButton } from '../../../IconComponent';
import { format } from 'react-string-format';


let AuthorTraining = () => {

    let training = require('./AuthorTraining.json');
    const { OverviewTitle, OverviewText, OverviewTextAdd, OverviewTextHelpLink, OverviewTextHelpLinkText, TrainingLinks, SecondaryText } = training[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {format(OverviewText, <a href={OverviewTextHelpLink} target="_blank">{OverviewTextHelpLinkText}</a>)}
                    </p>
                    <p>
                        {OverviewTextAdd}
                    </p>
                    <p>
                    {TrainingLinks.map((link) => {
                        return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28}/>;
                    })}
                    </p>
                    <p>
                        {SecondaryText} 
                    </p>
                </p>
            </CardBody>
        </>

    )
}

export { AuthorTraining };