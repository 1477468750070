import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";


let JobAids = () => {

    let jobaids = require('./JobAids.json');
    const { JobAidsOverviewTitle, JobAidsOverviewText } = jobaids[0];

    return (
        <>
            <CardTitle title={JobAidsOverviewTitle} alignment="left" />
            <CardBody alignment="left">

                <p>
                    {JobAidsOverviewText}
                </p>
            </CardBody>
        </>

    )
}

export { JobAids };