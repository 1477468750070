import React, { Component } from 'react';
import { Container, Row, TabletCol, Col, Card, CardIcon, CardTitle, CardBody, CardAction } from 'ui-library'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AppInsightsWrapper, CW } from './AppInsightsWrapper';
import { reactPlugin } from '../service/AppInsightsService';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    let json = require('./Home.json');
    const { MainText, Header,
        InterconnectionsTitle, InterconnectionsBodyText, InterconnectionsButtonText, InterconnectionsEventNameText,
        TrainingTitle, TrainingBodyText, TrainingButtonText, TrainingEventNameText,
        ReleaseNotesTitle, ReleaseNotesBodyText, ReleaseNotesButtonText, ReleaseNotesEventNameText,
        HelpTitle, HelpBodyText, HelpButtonText, HelpEventNameText
    } = json[0];
    
    return (
        <div className="text-center">
            <Container>
                <Row>
                    <Col colClass='grid-col'>
                     <p className='subheading'>{MainText}</p>
                    </Col>
                </Row>
            </Container>
            <br/>
            
            <h2 className="opm-textColor-navy">{Header}</h2>
            <br />
            <Container>
                <Row>
                    <TabletCol>
                        <Card autoWidth={false} alignment='right'>
                            <CardIcon image={<img src="/icon_interconnections.svg" alt={InterconnectionsTitle} />} />
                            <CardTitle title={InterconnectionsTitle} />
                            <CardBody>
                                {InterconnectionsBodyText}
                            </CardBody>
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <AppInsightsWrapper eventName={InterconnectionsEventNameText}>
                                    <CardAction title={InterconnectionsButtonText} url={'/Interconnections/GettingStarted'} />
                                </AppInsightsWrapper>   
                            </AppInsightsContext.Provider>
                        </Card>
                    </TabletCol>
                    <TabletCol>
                        <Card autoWidth={false} alignment='left'>
                            <CardIcon image={<img src="/icon_training.svg" alt={TrainingTitle} />} />
                            <CardTitle title={TrainingTitle} />
                            <CardBody>
                                {TrainingBodyText}
                            </CardBody>
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <AppInsightsWrapper eventName={TrainingEventNameText}>
                                    <CardAction title={TrainingButtonText} url={'/Training/Cognos'} />
                                </AppInsightsWrapper>   
                            </AppInsightsContext.Provider>
                        </Card>
                    </TabletCol>
                </Row>
                <Row rowSpacing='20px'>
                <TabletCol>
                        <Card autoWidth={false} alignment='right'>
                            <CardIcon image={<img src="/icon_releasenotes.svg" alt={ReleaseNotesTitle} />} />
                            <CardTitle title={ReleaseNotesTitle} />
                            <CardBody>
                                {ReleaseNotesBodyText}
                            </CardBody>
                            
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <AppInsightsWrapper eventName={ReleaseNotesEventNameText}>
                                    <CardAction title={ReleaseNotesButtonText} url={'/ReleaseNotes/Latest'} />
                                </AppInsightsWrapper>   
                            </AppInsightsContext.Provider>
                            
                        </Card>
                    </TabletCol>
                    <TabletCol>                        
                        <Card autoWidth={false} alignment='left'>
                            <CardIcon image={<img src="/icon_help.svg" alt={HelpTitle} />} />
                            <CardTitle title={HelpTitle} />
                            <CardBody>
                                {HelpBodyText}
                            </CardBody>
                            
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <AppInsightsWrapper eventName={HelpEventNameText}>
                                    <CardAction title={HelpButtonText} url={'/Help/FAQs'} />
                                </AppInsightsWrapper>   
                            </AppInsightsContext.Provider>
                        </Card>
                    </TabletCol>
                </Row>
            </Container>

            <br />
            <br />
            {/* <iframe title="Talent Surge Hiring Dashboard" width="100%" height="750" src="https://app.powerbigov.us/reportEmbed?reportId=08d4a4bc-099a-4ad2-a5e6-1a9ed533c282&autoAuth=true&ctid=3ce07c0b-30c5-4bcd-ad92-238975b649af" frameborder="0" allowFullScreen="true"></iframe> */}
                       
        </div>
    );
  }
}
