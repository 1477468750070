import React from "react";
import {
    CardTitle,
    CardBody
  } from "ui-library";
import { IconButton } from "../../IconComponent";


let RPITechSpecs = () => {

    //load text markup from json
    let rpi = require('./RPI.json');
    const { SecondaryTitle, SecondaryText, SecondaryLinks

    } = rpi[0];

    return (
        <>
            <CardTitle title={SecondaryTitle} alignment="left" />
            <CardBody alignment="left">
                    <p>
                        {SecondaryText}
                    </p>
                    <p>
                    {SecondaryLinks.sort((a, b) => a.Label.localeCompare(b.Label)).map((link)=> {
                            return <IconButton item={link} cssClass={'actionIcon'} iconSize={22}/>;
                        })}
                    </p>
            </CardBody>
        </>
        
    )
}

export { RPITechSpecs };