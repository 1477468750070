import React from 'react';
import { useLocation } from 'react-router-dom'
import { Nav, NavLink } from 'ui-library'

let LayoutNavigation = () => {
    const location = useLocation();
    var home = "/";
    var interconnections = "/Interconnections";
    var training = "/Training";
    var releaseNotes = "/ReleaseNotes";
    var help = "/Help";
    var isHomeActive = location.pathname == "/";
    var isInterconnectionsActive = location.pathname.includes(interconnections);
    var isTrainingActive = location.pathname.includes(training);
    var isReleaseNotesActive = location.pathname.includes(releaseNotes);
    var isHelpActive = location.pathname.includes(help);
    return (
        <div>
            <Nav>
                <NavLink title={"Home"} url={home} isActive={isHomeActive} />
                <NavLink title={"Interconnections"} url={interconnections + "/GettingStarted"} isActive={isInterconnectionsActive} />
                <NavLink title={"Training & Resources"} url={training + "/Cognos"} isActive={isTrainingActive} />
                <NavLink title={"Release Notes"} url={releaseNotes + "/ReleaseSchedule"} isActive={isReleaseNotesActive} />
                <NavLink title={"Help"} url={help + "/FAQs"} isActive={isHelpActive} />
            </Nav>
        </div>
    );
};

export { LayoutNavigation };