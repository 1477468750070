import React from "react";
import { useLocation } from "react-router-dom";
import { SideNav, SideNavLink } from "ui-library";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

let SideNavigation = ({pageKey}) => {
    const location = useLocation();
    let json = require('./SideNav.json');
    const { Pages } = json[0];
    console.log(pageKey);

    return (
        <div>
            <SideNav>

                {Pages.filter(p => p.Page == pageKey).map((page) => {
                    return (
                        page.NavigationItems.map((navItem) => {

                            return (
                                <SideNavLink key={navItem.id}
                                    title={navItem.Label}
                                    isActive={location.pathname.includes(navItem.Path)}
                                    url={navItem.Path}>

                                    {navItem.SubItems.map((subItem) => {
                                        return (
                                            <SideNavLink
                                                title={subItem.Label}
                                                isActive={location.pathname.includes(subItem.Path)}
                                                url={subItem.Path}>

                                                {subItem.SubItems.map((subSubItem) => {
                                                    return (
                                                        <SideNavLink
                                                            title={subSubItem.Label}
                                                            isActive={location.pathname.includes(subSubItem.Path)}
                                                            url={subSubItem.Path} />
                                                    );
                                                })}
                                            </SideNavLink>
                                        );
                                    })}

                                </SideNavLink>
                            );

                        })
                    );
                })}
            </SideNav>
        </div>
    );
};

export { SideNavigation };
