import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { IconButton } from '../../IconComponent';

let WorkgroupDetails = (props) => {

    const { ContentFile, DataType } = props;
    let content = require(`${ContentFile}`);
    const { VideoTitle, WorkgroupLinks } = content[0];
    return (
        <>
            <CardBody alignment="left">
                {DataType === 'mp4' && VideoTitle && (
                    <p style={{ marginLeft: '25px' }}>{VideoTitle}</p>
                )}
                {WorkgroupLinks.map((link, index) => {
                    var vids = link.Links.filter(function (item) {
                        return item.Extension === DataType;
                    });

                    if (vids?.length > 0) {
                        return (
                            <React.Fragment key={index}>
                                <CardTitle title={link.Year} alignment="left" />
                                <CardBody alignment="left">
                                    <p>
                                        {vids?.map((item, idx) => {
                                            if (item.AccessMessage?.length > 0) {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <p>{item.AccessMessage}</p>
                                                        <IconButton item={item} cssClass={'resourceIcon'} iconSize={22} />
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return (
                                                    <IconButton key={idx} item={item} cssClass={'resourceIcon'} iconSize={22} />
                                                );
                                            }
                                        })}
                                    </p>
                                </CardBody>
                            </React.Fragment>
                        );
                    }
                })}
            </CardBody>
        </>
    );
}

export { WorkgroupDetails }