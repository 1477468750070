import React from "react";
import { format } from 'react-string-format';
import {
    Container,
    Row,
    CardTitle,
    CardBody,
    Accordion,
    AccordionSection,
    TabletCol
} from "ui-library";

let DataAPIs = () => {

    //load text markup from json
    let json = require('./DataAPIs.json');
    const { title, OverviewMainText, OverviewLinkText, OverviewLinkFirstUrl,
        OverviewLinkSecondUrl, OverviewSecondaryText,
        OverviewSecondaryLinkText, OverviewSecondaryLinkFirstUrl, OverviewSecondaryLinkSecondUrl,
        AccordionTitleImageSource, AccordionSectionOneTitle, AccordionSectionOneText,
        AccordionSectionTwoTitle, AccordionSectionTwoText, AccordionSectionTwoEndpointTableList,
        AccordionSectionTwoSecondaryText,
        AccordionSectionThreeTitle, AccordionSectionThreeText,
        AccordionSectionFourTitle, AccordionSectionFourText, AccordionSectionFourExpandsTableList,
        AccordionSectionFiveTitle, AccordionSectionFiveText,
        AccordionSectionFiveSecondaryText, AccordionSectionFiveList, AccordionSectionSixTitle, AccordionSectionSixText,
        AccordionSectionSixQuotaList, AccordionSectionSixRateList, AccordionSectionSixLimitList, AccordionSection429Text

    } = json[0];
    return (
        <>
            <CardTitle title={title} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p>
                        {OverviewMainText}
                    </p>
                    <p>
                        {OverviewLinkText}
                        <br />
                        <br />
                        <b>{OverviewLinkFirstUrl}</b>
                        <br />
                        <b>{OverviewLinkSecondUrl}</b>
                        <br />
                    </p>
                    <p>{OverviewSecondaryText}</p>
                    <p dangerouslySetInnerHTML={{ __html: OverviewSecondaryLinkText }} />
                    <p>

                        <b>{OverviewSecondaryLinkFirstUrl}</b>
                        <br />
                        <b>{OverviewSecondaryLinkSecondUrl}</b>
                    </p>
                </p>
                <Accordion>
                    <AccordionSection id="d1" title={AccordionSectionOneTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionOneText }} />
                        </div>
                    </AccordionSection>
                    <AccordionSection id="d2" title={AccordionSectionTwoTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionTwoText }} />
                            <table class="usa-table">
                                <tbody>
                                    {AccordionSectionTwoEndpointTableList.map((arr) => {
                                        return <tr>
                                            <th scope="row">{arr.label}</th>
                                            <td>
                                                <span dangerouslySetInnerHTML={{ __html: arr.description }} />
                                            </td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionTwoSecondaryText }} />
                        </div>
                    </AccordionSection>
                    <AccordionSection id="d3" title={AccordionSectionThreeTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionThreeText }} />
                        </div>
                    </AccordionSection>
                    <AccordionSection id="d4" title={AccordionSectionFourTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionFourText }} />
                            <table class="usa-table">
                                <tbody>
                                    {AccordionSectionFourExpandsTableList.map((arr) => {
                                        return <tr>
                                            <th scope="row">{arr.label}</th>
                                            <td>
                                                <span dangerouslySetInnerHTML={{ __html: arr.description }} />
                                            </td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </AccordionSection>
                    <AccordionSection id="d5" title={AccordionSectionFiveTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionFiveText }} />
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionFiveSecondaryText }} />
                            <ul>
                                {AccordionSectionFiveList.map((arr) => {
                                    return <li dangerouslySetInnerHTML={{ __html: arr.item }} />;
                                })}
                            </ul>
                        </div>
                    </AccordionSection>
                    <AccordionSection id="d6" title={AccordionSectionSixTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: AccordionSectionSixText }} />
                            <table class="usa-table">
                                <tbody>
                                    {AccordionSectionSixQuotaList.map((arr) => {
                                        return <tr>
                                            <th style={{ minWidth: "12em" }} scope="row"><span dangerouslySetInnerHTML={{ __html: arr.Label }} /></th>
                                            <td style={{ minWidth: "13em" }}>
                                                <span dangerouslySetInnerHTML={{ __html: arr.Title }} />
                                            </td>
                                            <td style={{ maxWidth: "50em" }}>
                                                <span dangerouslySetInnerHTML={{ __html: arr.Description }} />
                                            </td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                            <table class="usa-table">
                                <tbody>
                                    {AccordionSectionSixRateList.map((arr) => {
                                        return <tr>
                                            <th style={{ minWidth: "12em" }} scope="row"><span dangerouslySetInnerHTML={{ __html: arr.Label }} /></th>
                                            <td style={{ minWidth: "13em" }}>
                                                <span dangerouslySetInnerHTML={{ __html: arr.Title }} />
                                            </td>
                                            <td style={{ maxWidth: "50em" }}>
                                                <span dangerouslySetInnerHTML={{ __html: arr.Description }} />
                                            </td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>

                            <p dangerouslySetInnerHTML={{ __html: AccordionSection429Text }} />
                            <table class="usa-table">
                                <tbody>
                                    {AccordionSectionSixLimitList.map((arr) => {
                                        return <tr>
                                            <th style={{ minWidth: "12em" }} scope="row"><span dangerouslySetInnerHTML={{ __html: arr.Label }} /></th>
                                            <td style={{ minWidth: "13em" }}>
                                                <span dangerouslySetInnerHTML={{ __html: arr.Title }} />
                                            </td>
                                            <td style={{ maxWidth: "50em" }}>
                                                <span dangerouslySetInnerHTML={{ __html: arr.Description }} />
                                            </td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </AccordionSection>
                </Accordion>
            </CardBody>
        </>

    )
}
export { DataAPIs };