import React from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const AppInsightsWrapper = (props) => {
    const appInsights = useAppInsightsContext();
    
    function handleClick() {
        console.log(props.eventName);
        appInsights.trackEvent({name: props.eventName, properties: props.value });
    }

    return (
        <>{ React.cloneElement( props.children, { customClick: handleClick } ) }</>
    );
};

export { AppInsightsWrapper };