import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";


let PowerBIReportConsumer = () => {

    let reportconsumer = require('./ReportConsumer.json');
    const { ConsumerOverviewTitle, ConsumerOverviewText } = reportconsumer[0];

    return (
        <>
            <CardTitle title={ConsumerOverviewTitle} alignment="left" />
            <CardBody alignment="left">

                <p>
                    {ConsumerOverviewText}
                </p>
            </CardBody>
        </>

    )
}

export { PowerBIReportConsumer };