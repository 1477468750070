import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";

let PowerBIConsumerTraining = () => {

    let consumertraining = require('./ConsumerTraining.json');
    const { OverviewTitle, OverviewText } = consumertraining[0];
    const videoURL = 'https://youtu.be/cZsQj1WFkGE?si=uwI_QHtKq43douki';
    const powerBILink = 'https://learn.microsoft.com/en-us/power-bi/fundamentals/power-bi-service-overview';

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    {<p dangerouslySetInnerHTML={{ __html: OverviewText }} />}
                </p>
                <br />
                <iframe
                    width="560"
                    height="315"
                    src={videoURL.replace("youtu.be/", "www.youtube.com/embed/").split('?')[0]}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
                <br /><br />
                <a href={powerBILink} target="_blank" rel="noopener noreferrer">Power BI Service Overview</a>
            </CardBody>
        </>
    )
}

export { PowerBIConsumerTraining };