import {React,  useState, useEffect } from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import { Container, Row, Col } from 'ui-library'
import { getQueryString } from './Utils.js'


let SearchContainer =() => {

    const [searchQuery, setSearchQuery] = useState(getQueryString("searchQuery") ?? "");

    const performSearch = (e)=> {
        setSearchQuery(e.target.value);
    }

   const handleSubmit = (e) => {
        e.preventDefault();
        const encodedQuery = encodeURIComponent(searchQuery);
        location.href = '/Search?searchQuery=' + encodedQuery;
    }

        return (
            <>
            <div className="text-center" style={{ paddingTop: "15px" }}>
                <Container>
                    <Row>
                        <Col colClass='grid-col'>
                                <form style={{ width: "100%", display: 'grid' }} onSubmit={handleSubmit}>
                                    <div  className="search-container">
                                        <input value={searchQuery} aria-label="Search Bar"
                                        id="Search" type="search" alt="Search Bar"
                                            className="usa-input" placeholder="Search"
                                        onChange={performSearch}
                                        
                                    />
                                    <button type="submit" className="usa-button" aria-label="Search Button" title="Search"
                                        style={{ height: "2.5rem", padding: 0, backgroundColor: "rgba(215, 215, 215, 1)", borderRadius: "0px 4px 4px 0px", width: "2.5rem" }} >
                                        <span style={{ color: "#333333", fontWeight: "bold" }} ><AiOutlineSearch size="35" /></span>
                                    </button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>

            </div>
            </>
        )
   


};

export { SearchContainer };