import { React } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSearchParams } from "react-router-dom";
import {
    Accordion, TabletCol, Container, Row,
    AccordionSection,
} from "ui-library";

// retrieves the querystring object.
export const getQueryString = (param) => {
    const [searchParams] = useSearchParams();
    return searchParams.get(param);
};

// navigates to a specific FAQ (mostly from the search result).
export function goToResource(id) {

    const targetFAQ = document.getElementById(id);
    if (targetFAQ !== null) {
        window.scrollTo({
            top: targetFAQ.offsetTop,
            behavior: "smooth"
        });
    }
};

export const VideoTabletCol = ({ url, name, description, supportDocument, supportDocumentFileName, containerWidth = "100%", videoWidth = "100%", padLeft = '2em', padRight = '2em', videoPoster = '' }) => {
    var UNSUPPORTED_BROWSER = "Your browser does not support HTML video.";
    var desc = description ?? "";
    var titleName = desc.length > 0 ? `${name}:` : name;
   return (
       <>
           <div className="grid-col-6">
            <TabletCol>
                <Container>
                    <Row>
                        <TabletCol>
                            <div style={{ alignment: "left", width: containerWidth, paddingLeft: padLeft, paddingRight: padRight }}>
                               <TabletCol><b>{titleName}</b> {desc}</TabletCol>
                                <TabletCol><a href={supportDocument??""} target="_blank">{supportDocumentFileName??""}</a> </TabletCol>
                                <TabletCol></TabletCol>
                                <video poster={videoPoster} width={videoWidth} controls>
                                    <source src={url} type="video/mp4" />
                                    {UNSUPPORTED_BROWSER}
                                </video>
                            </div>
                        </TabletCol>
                    </Row>
                </Container>
            </TabletCol>
                            </div>
        </>
    )
}


export const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const TabBox = ({ indexValue, changeHandler, title }) => {
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={indexValue} onChange={changeHandler} aria-label={title}>
                    <Tab label="Functional Questions" {...a11yProps(0)} />
                    <Tab label="Technical Questions" {...a11yProps(1)} />
                </Tabs>
            </Box>
        </>
    );
}

export const FAQTabPanel = ({ indexValue, indexId, helpId, accodionList }) => {
    return (accodionList !== null ? (
        <>
            <CustomTabPanel value={indexValue} index={indexId}>
                <FAQAccordion List={accodionList} HelpId={helpId} />
            </CustomTabPanel>
        </>) : null
    );
}

export const FAQAccordion = ({ List, HelpId }) => {
    return (List ? (
        <>
            <Accordion>
                {List.map((arr) => {
                    return (
                        <AccordionSection title={arr.Title} id={arr.ID} isExpanded={arr.ID == HelpId} titleImageSource="/img/question.svg">
                            <div><p dangerouslySetInnerHTML={{ __html: arr.Content }} />
                                <FAQSubList subList={arr.SubList} />
                            </div>
                        </AccordionSection>)
                        ;
                })}
            </Accordion>
        </>) : null
    );
}

export const FAQSubList = ({ subList }) => {
    return (subList ?
        (
            <>
                <ol>
                    {subList.map((list) => { return <li>{list.rtfitem}</li> })}

                </ol>
            </>

        ) : null
    );
}




