import { React } from "react";
import { TicketBaseForm } from './TicketBaseForm'




let TicketForm = (props) => {

    switch (props.formName) {
        case "RPI": return <>
            <div className="usa-form-group">
                <label htmlFor="VacancyID" className="usa-label usa-help-maxwidth">Please provide the vacancy or request information where the error is occurring<abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                <input name="VacancyID" value={props.ticket.VacancyID || ""} onChange={props.changeHandler} required placeholder="Vacancy ID or Request Number" className="usa-input" id="VacancyID" />
            </div>
            <TicketBaseForm changeHandler={props.changeHandler} ticket={props.ticket} ReportList={props.ReportList} ReportTypes={props.ReportTypes} />

        </>
        case "DataAPI": return (
            <>
                <div className="usa-form-group">
                    <label htmlFor="APIsBeingUsed" className="usa-label">Provide the API(s) that are being affected by the issue</label>
                    <input value={props.ticket.APIsBeingUsed || ""} onChange={props.changeHandler} name="APIsBeingUsed" placeholder="APIs Being Used" className="usa-input" id="APIsBeingUsed" />
                </div>
                <div className="usa-form-group">
                    <label htmlFor="APIURL" className="usa-label">Provide the URL(s) being called where the issue is occurring</label>
                    <textarea spellCheck="true" style={{ height: "4rem" }} placeholder="API URL" onChange={props.changeHandler} value={props.ticket.APIURL || ""} name="APIURL" className="usa-textarea usa-input" id="APIURL"></textarea>
                </div>
                <TicketBaseForm changeHandler={props.changeHandler} ticket={props.ticket} ReportList={props.ReportList} ReportTypes={props.ReportTypes} />
            </>
        )
        case "NHI": return (
            <>
                <div className="grid-row grid-gap">
                    <div className="grid-col-6 usa-form-group">
                        <label htmlFor="NewHireNumber" className="usa-label usa-help-maxwidth">Provide the New Hire Number(s) where the error is occurring</label>
                        <input value={props.ticket.NewHireNumber || ""} onChange={props.changeHandler} name="NewHireNumber" placeholder="New Hire Number" className="usa-input" id="NewHireNumber" />
                    </div>
                    <div className="grid-col-6 usa-form-group">
                        <label htmlFor="NewHireName" className="usa-label usa-help-maxwidth">Provide the New Hire Name(s) where the error is occurring</label>
                        <input value={props.ticket.NewHireName || ""} onChange={props.changeHandler} name="NewHireName" placeholder="New Hire Name" className="usa-input" id="NewHireName" />
                    </div>
                </div>
                <TicketBaseForm changeHandler={props.changeHandler} ticket={props.ticket} ReportList={props.ReportList} ReportTypes={props.ReportTypes} />

            </>
        )
        case "Reports": case "Power BI": return <>

            <TicketBaseForm changeHandler={props.changeHandler} ticket={props.ticket} ReportList={props.ReportList} ReportTypes={props.ReportTypes} Tenants={props.Tenants} />

        </>
        default: return <div></div>
    }

};



export { TicketForm };