import React, {useState, useEffect} from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { DataDictionaryTabs } from "./DataDictionaryTabs";
import { getQueryString } from '../../../Utils.js'


let DataDictionary = () => {

    //load text markup from json
    let overviewJson = require('../DataAPIs.json');
    var api = getQueryString("api");

    const { DataDictionaryTitle, DataDictionaryMainText
    } = overviewJson[0];
    return (
        <>
            <CardTitle title={DataDictionaryTitle} alignment="left" />
            <CardBody alignment="left">
                <div>
                    <p>
                        {DataDictionaryMainText}
                    </p>
                    <DataDictionaryTabs Entity={api} />
                </div>
            </CardBody>
        </>

    )
}
export { DataDictionary };