import React from "react";
import {
    CardTitle,
    CardBody,
    Container,
    Row,
    Accordion,
    AccordionSection
} from "ui-library";
import { IconButton } from '../../IconComponent';
import { VideoTabletCol } from '../../Utils.js'


let PowerBIJobAids = () => {

    let jobaids = require('./JobAids.json');
    const {
        JobAidsOverviewTitle, JobAidsOverviewText,
        JobAids_Video_Links,
        JobAids_Document_Links,
        JobAids_Document_Links_2,
        AccordionStepOneTitle, AccordionStepOneText,
        AccordionStepTwoTitle, AccordionStepTwoText,
        AccordionTitleImageSource } = jobaids[0];


    return (
        <>
            <CardTitle title={JobAidsOverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>{JobAidsOverviewText}</p>

                <Accordion>
                    <AccordionSection id="gs1" title={AccordionStepOneTitle} titleImageSource={AccordionTitleImageSource}>
                        <div>
                            <p style={{ textAlign: "center" }}>
                                {AccordionStepOneText}
                            </p>
                            <Container>
                          <Container >
                            <br />
                                {JobAids_Document_Links_2.map((link) => {
                                    return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28} />;
                                })}
                            </Container>
                                <Row style={{ alignment: "left" }}>
                                    {JobAids_Video_Links.map((obj) => {
                                        return (<
                                            VideoTabletCol url={obj.URL} 
                                            name={obj.Label} 
                                            description={obj.SubTitle} 
                                            supportDocument={obj.SupportDocumentDownload} 
                                            supportDocumentFileName={obj.SupportDocumentDownload_FileName} 
                                            videoPoster={obj.Poster}
                                            />);
                                    })}
                                </Row>
                            </Container>

                        </div>

                    </AccordionSection>
                    <AccordionSection id="gs2" title={AccordionStepTwoTitle} titleImageSource={AccordionTitleImageSource} >
                        <div>
                            <p>
                                {AccordionStepTwoText}
                            </p>
                            <Container >
                                {JobAids_Document_Links.map((link) => {
                                    return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28} />;
                                })}
                            </Container>
                        </div>
                    </AccordionSection>
                </Accordion>

            </CardBody>
        </>

    )
}

export { PowerBIJobAids };