import React from "react";
import {
    CardTitle,
    CardBody, ResourceButton

} from "ui-library";
import { BsFileText } from 'react-icons/bs';
import { IconButton } from "../IconComponent";


let ReleaseNotesPage = (props) => {
    const { ContentFile } = props;
    let releaseNotes = require(`${ContentFile}`);
    const { OverviewTitle, NotesLinks } = releaseNotes[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">

                <p>
                    {NotesLinks.map((link) => {
                        return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28} icon={'txt'} />;
                    })}
                </p>


            </CardBody>
        </>

    )
}

export { ReleaseNotesPage };
