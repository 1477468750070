import { React, useState, useEffect } from "react";
import {
    CardTitle,
    CardBody,
} from "ui-library";

import PropTypes from 'prop-types';
import { CustomTabPanel, goToResource, getQueryString, TabBox, FAQAccordion, FAQTabPanel } from '../../Utils.js'

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


let FAQPages = ({ ContentFile, DefaultFAQ, HasTabs = false }) => {

    //load text markup from json
    let faqFile = require(`${ContentFile}`);
    const { OverviewTitle, AccordionLists, FunctionalLists, TechnicalLists } = faqFile[0];
    let tabId = getQueryString('tab');
    const [value, setValue] = useState(parseInt(tabId ?? 0));
    var helpId = getQueryString("help") ?? DefaultFAQ;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function goToFAQs() {
        goToResource(helpId);
    };


    useEffect(() => {

        // Attach the callback after the component mounts
        window.addEventListener("load", goToFAQs, true);

        // Detach the callback before the component unmounts
        return () => window.removeEventListener("load", goToFAQs, true);
    }, []);



    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <FAQAccordion List={AccordionLists} HelpId={helpId} />

                {HasTabs ?
                    <>
                        <TabBox indexValue={value} hasTab={HasTabs} changeHandler={handleChange} title={OverviewTitle} />
                        <FAQTabPanel indexValue={value} accodionList={FunctionalLists} indexId={0} helpId={helpId} />
                        <FAQTabPanel indexValue={value} accodionList={TechnicalLists} indexId={1} helpId={helpId} />

                    </> :
                    <></>
                }

            </CardBody>
        </>

    )

}

export { FAQPages };