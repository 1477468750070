import React, { useState, useEffect } from 'react'
import {Autocomplete, TextField } from "@mui/material";
import { placeholder } from '@babel/types';


let SearchFilter = ({ label, placeholder, list, keywords=[], onChange=()=>{} }) => {
    
    var flattenedEntityDatas = list
            .map(entity => {
                return entity.DataEntityFields;
            })
            .flat();

    var dataFields = [];
    flattenedEntityDatas.map((item)=>{
        if(!dataFields.includes(item.Name))
        {
            dataFields.push(item.Name.trim());
        }
    });

    const sortedList = dataFields.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    const onTagsChange = (event, values) => {
        
        if(values)
        {
            var keywords = [];
            {values.map((obj)=>{
                if(typeof obj === 'string' || obj instanceof String)
                    keywords.push(obj);
                else
                    keywords.push(obj.Name);
            })}
            
            onChange(keywords);
        }
        
    };
    
    return (
        <>
            <Autocomplete
                multiple
                freeSolo
                options={sortedList}
                getOptionLabel={option => option.Name || option}
                defaultValue={keywords}
                onChange={onTagsChange}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={label}
                        placeholder={placeholder}
                        margin="normal"
                        fullWidth
                    />
                )}
            />
        </>
    );
};

export { SearchFilter };
