import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { SideNavigation } from "../SideNavigation";
import { IconButton } from "../IconComponent";

import {
  Container,
  Row,
  Col,
  Card
} from "ui-library";

export class Documentation extends Component {
  

  render() {
    let json = require('./Documentation.json');
    const { Header, MainText, RPIDocLinks } = json[0];

    return (
      <div className="text-center">
        <br />
        <h2 className="opm-textColor-navy">{Header}</h2>
        <br />
        <Container>
          <Row>
            <Col colClass="grid-col">
              <p className="subheading">
                {MainText}
              </p>
            </Col>
          </Row>
        </Container>
        <br />
        <Container>
          <Row>
            <Col colClass="grid-col-2">
              <div style={{ height: "400px" }}>
                <Card autoWidth={true}>
                  <div style={{
                      fontSize: "1em",
                      textAlign: "left",
                      padding: "10px",
                    }}>
                    Navigation
                    <br />
                    <br />
                    <SideNavigation pageKey="Interconnections"/>
                  </div>
                </Card>
              </div>
            </Col>
            <Col colClass="grid-col-10">
              <Card autoWidth={true}>
                <Outlet/>
                <p>
                {RPIDocLinks.map((link) => {
                  return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28}/>;
                })}
              </p>
              </Card>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
      </div>

      
    );
  }

  
}
