import React from "react";
import {
    CardTitle,
    CardBody,
    ActionButton,
    Container,
    TabletCol,
    Row
} from "ui-library";

import { AiOutlineArrowRight } from "react-icons/ai";

let Documentation = () => {

    //load text markup from json
    let overviewJson = require('../DataAPIs.json');

    const { DocumentationTitle, DocumentationMainText
    } = overviewJson[0];

    let apisJson = require('../ApiInfos.json');
    var apisCount = apisJson.length;
    var firstColumnApis = apisJson.slice(0, Math.round(apisCount / 2));
    var secondColumnApis = apisJson.slice(Math.round(apisCount / 2), apisJson.length);
    var aiClass = 'actionIcon';
    var aiSize = 22;
    return (
        <>
            <CardTitle title={DocumentationTitle} alignment="left" />
            <CardBody alignment="left">
                <p dangerouslySetInnerHTML={{ __html: DocumentationMainText }} />
                <div>
                    <Container>
                        <Row>
                            <TabletCol>
                                {firstColumnApis.map((info) => {
                                    var nameParam = info.Name.replace(/\s+/g, '');
                                    return (
                                        <div className="actionButtonContainerLeft">
                                            <ActionButton title={info.Name} openInNewTab={false} url={"/Interconnections/DataAPIs/Documentation/Details?api=" + nameParam} reactIcon={<AiOutlineArrowRight className={aiClass} size={aiSize} />} />
                                        </div>
                                    )
                                })}

                            </TabletCol>

                            <TabletCol>
                                {secondColumnApis.map((info) => {
                                    var nameParam = info.Name.replace(/\s+/g, '');
                                    return (
                                        <div className="actionButtonContainerRight">
                                            <ActionButton title={info.Name} openInNewTab={false} url={"/Interconnections/DataAPIs/Documentation/Details?api=" + nameParam} reactIcon={<AiOutlineArrowRight className={aiClass} size={aiSize} />} />
                                        </div>
                                    )
                                })}
                            </TabletCol>
                        </Row>
                    </Container>
                </div>
            </CardBody>
        </>

    )
}
export { Documentation };