import React from "react";
import {
    CardTitle,
    CardBody

} from "ui-library";
import { IconButton } from "../IconComponent";


let ReleaseSchedule = () => {

    const { OverviewTitle, DownloadLink, ImageLink } = require('./ReleaseSchedule.json')[0];


    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <img style={{ display: 'block', margin: 'auto' }} src={ImageLink} />
                    <br />
                    <IconButton item={DownloadLink} cssClass={'resourceIcon'} iconSize={28} />

                </p>
            </CardBody>
        </>
    )
}

export { ReleaseSchedule };