import React, { Component } from 'react';
import './custom.css';

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from "./index";
import { Pages } from './Pages';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

export default class App extends Component {
    
    static displayName = App.name;
    render() {
        return (
            // <MsalProvider instance={msalInstance}>
            //    <Pages/>
            // {/* </MsalProvider> */}
            <Pages />
        );
    }
}
