import { React, useState, useEffect } from "react";
import {
    CardTitle,
    CardBody,
} from "ui-library";
import { TicketForm } from './TicketForm'


let SubmitTicket = () => {
    const [ticket, setTicket] = useState({});
    const [loaded, setLoading] = useState(false);
    const [reportTypes, setReportTypes] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [tenantList, setTenantList] = useState([]);
    const [issueList, setIssueList] = useState([]);
    const [processing, setProcess] = useState(false);
    const [dataSent, setDataSent] = useState(false);
    const [resMessage, setResponseMessage] = useState("Failed to send data.");
    const [isError, setError] = useState(false);

    const getReportList = (list) => {
        fetch(`helpdesk/${list}`, 
            {
                method: 'GET', 
            })
            .then((results) => {
                return results.json();
            })
            .then(data => {

                console.log(data);
                if (list === "issues") {

                    setIssueList(data);
                } else if (list === "tenants") {

                    setTenantList(data);
                } else if (list === "Power BI" || list === "Reports") {

                    setReportList(data);
                } else {
                    data.sort();
                    setReportTypes(data);

                }

                setLoading(true);
            }).catch((error) => { console.log(error) })

    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setTicket(values => ({ ...values, [name]: value }))

        if (name === "InterconnectionType") {
            if (value === "Power BI" || value === "Reports") {
                getReportList(value);
                getReportList("tenants");
                setTicket(values => ({ ...values, IssueType: "Reports" }))

            } else {
                setTicket(values => ({ ...values, IssueType: "Interconnections" }))
            }
        }

        if (name === "ReportRequestType") {

            getReportList(`${ticket.InterconnectionType}|${value}`);
        }

    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(ticket);
        setProcess(true);

        try {
            const response = await fetch('helpdesk', {
                method: 'POST',
                body: JSON.stringify(ticket),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            // Check if the response is successful
            if (response.ok) {
                var data = await response.json();

                // Check if the FootPrint returned a success
                setError(data.response.includes("Error"))

                // Display response message
                setResponseMessage(data.response);

            } else {
                setError(true);
            }
        } catch (error) {

            console.error('Error:', error);
            setError(true);
            setProcess(true);
        }

        setDataSent(true);

    }

    //jumps to the home page
    const clearMessage = (e) => {

        if (isError) {

            setDataSent(false);
            setProcess(false);

        } else {
            location.href = '/Help/FAQs'
        };
    }

    useEffect(() => {

        if (!loaded) {
            getReportList("issues");
        }
    }, []);


    return (
        <>
            <CardTitle title="Submit Help Ticket" alignment="left" />
            <CardBody alignment="left">
                {dataSent ?
                    <>
                        <div className="grid-row grid-gap">
                            <div className="grid-col-12">
                                <div class="usa-prose margin-top-6">
                                    <div className="page-heading-spacer"><hr className="page-heading-spacer" /></div>
                                    <div className="usa-prose text-success margin-top-6">
                                        <p dangerouslySetInnerHTML={{ __html: resMessage }} style={{ color: isError ? "#d13138" : "#0a3553", fontWeight: '900' }} />
                                    </div>
                                    <div className="usa-form-group">
                                        <input type="button" onClick={clearMessage} value="OK" className="usa-button" />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </> :
                    <>
                        <form id="HelpDeskForm" onSubmit={handleSubmit} style={{ minWidth: "100%" }} className="usa-form usa-form--large usa-help-maxwidth"  >
                            <p>
                                Instructions: In order to submit a ticket about your issue, please respond to each of the questions below and provide specific information which may be needed to research and resolve your request. After submitting, we will respond to the ticket via email to update you on a resolution.
                            </p>
                            <fieldset disabled={processing} className="usa-fieldset">

                                <div className="grid-row grid-gap">
                                    <div className="grid-col-6 usa-form-group">
                                        <label htmlFor="FirstName" className="usa-label">First Name <abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                                        <input value={ticket.FirstName || ""} name="FirstName" id="FirstName" onChange={handleChange} required placeholder="First Name" className="usa-input" />
                                        <span class="text-danger field-validation-valid" data-valmsg-for="FirstName" data-valmsg-replace="true"></span>                            </div>
                                    <div className="grid-col-6 usa-form-group">
                                        <label htmlFor="LastName" className="usa-label">Last Name <abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                                        <input value={ticket.LastName || ""} name="LastName" id="LastName" onChange={handleChange} required placeholder="Last Name" className="usa-input" />
                                    </div>

                                </div>
                                <div className="usa-form-group">
                                    <label htmlFor="Email" className="usa-label">Email Address<abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                                    <input value={ticket.Email || ""} name="Email" id="Email" onChange={handleChange} required placeholder="Email Address" type="email" className="usa-input" />
                                </div>
                                <div className="usa-form-group">
                                    <label htmlFor="CC" className="usa-label usa-help-maxwidth">Please enter any additional recipients who should receive a copy of this form submission. Separate multiple email addresses with commas.</label>
                                    <input value={ticket.CC || ""} name="CC" id="CC" onChange={handleChange} placeholder=" CC Email Address(es)" className="usa-input" />
                                </div>
                                <div className="grid-row grid-gap">
                                    <div className="grid-col-6 usa-form-group">
                                        <label htmlFor="Subject" className="usa-label">Please enter a subject for the problem being encountered. <abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>
                                        <input value={ticket.Subject || ""} name="Subject" onChange={handleChange} required placeholder="Subject" className="usa-input" id="Subject" />
                                    </div>
                                    <div className="grid-col-6 usa-form-group">
                                        <label htmlFor="PhoneNumber" className="usa-label">Phone Number</label>
                                        <input value={ticket.PhoneNumber || ""} name="PhoneNumber" onChange={handleChange} placeholder="Phone Number" className="usa-input" id="PhoneNumber" />
                                    </div>
                                </div>
                                <div className="grid-col-6 usa-form-group">
                                    <label htmlFor="InterconnectionType" className="usa-label">Select the area for the issue or request. <abbr title="required" className="usa-hint usa-hint--required">*</abbr></label>

                                    <select value={ticket.InterconnectionType || ""} name="InterconnectionType" onChange={handleChange} required className="usa-select InterconnectionType" id="InterconnectionType">
                                        <option value="">Select Issue or Request Area</option>
                                       {issueList?.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.text}
                                            </option>
                                        ))}
                                    </select>

                                </div>


                                <div className="interconnectedForms" id="interconnectedForms">
                                    <TicketForm ticket={ticket} changeHandler={handleChange} formName={ticket.InterconnectionType} ReportList={reportList} ReportTypes={reportTypes} Tenants={tenantList} />
                                </div>

                                <div className="usa-form-group">
                                    <input type="submit" value="Submit" className="usa-button" />
                                </div>
                            </fieldset>
                        </form>

                    </>
                }

            </CardBody>
        </>

    )

}




export { SubmitTicket };