import React from 'react'

let DataEntityTableView = ({data}) => {
    
    return (
        <>
            <table className="usa-table usa-table--borderless">
                <thead>
                    <tr>
                        <th scope="col">API Entity</th>
                        <th scope="col">Data Item</th>
                        <th scope="col">Data Type</th>
                        <th scope="col">Definition</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((field) => {
                        return (<tr>
                            <th scope="row">{field.DataEntityField}</th>
                            <td>{(field.Name)}</td>
                            <td>{(field.Type)}{field.Type=="string" || field.Type=="string (required)" ? (<> &#40;{field.StringLength}&#41;</>) : (<></>)}</td>
                            <td>{(field.Description) }</td>
                        </tr>);
                    })}
                    
                    
                </tbody>
            </table>
        </>
    );
};

export { DataEntityTableView };
