import React, { Component, useState } from "react";
import { Routes, Route, Link, Outlet, useLocation } from "react-router-dom";
import { SideNavigation } from "../SideNavigation";

import {
    Container,
    Row,
    Col,
    TabletCol,
    Card,
    SideNav,
    SideNavLink,
} from "ui-library";

export class Training extends Component {


  render() {
      let json = require('./Training.json');
      const { Header, MainText } = json[0];

      return (
          <div className="text-center">
              <br />
              <h2 className="opm-textColor-navy">{Header}</h2>
              <br />
              <Container>
                <Row>
                    <Col colClass="grid-col">
                    <p className="subheading">
                        {MainText}
                    </p>
                    </Col>
                </Row>
        </Container>
        <br />
              <Container>
                  <Row>
                      <Col colClass="grid-col-2">
                          <div style={{ height: "400px" }}>
                              <Card autoWidth={true}>
                                  <div style={{
                                      fontSize: "1em",
                                      textAlign: "left",
                                      padding: "10px",
                                  }}>
                                      Navigation
                                      <br />
                                      <br />
                                      <SideNavigation pageKey="Training" />
                                  </div>
                              </Card>
                          </div>
                      </Col>
                      <Col colClass="grid-col-10">
                          <Card autoWidth={true}>
                              <Outlet />
                          </Card>
                      </Col>
                  </Row>
              </Container>
              <br />
              <br />
          </div>
      );
  }

}

