import React from "react";
import {
    CardTitle,
    CardBody
} from "ui-library";
import { IconButton } from '../../../IconComponent';


let ConsumerTraining = () => {

    let consumertraining = require('./ConsumerTraining.json');
    const { OverviewTitle, OverviewText, TrainingLinks, SecondaryText } = consumertraining[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p>
                    <p dangerouslySetInnerHTML={ {__html: OverviewText} }/>
                    <p>
                    {TrainingLinks.map((link) => {
                        return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28}/>;
                    })}
                    </p>
                    <p>
                        {SecondaryText}
                    </p>
                </p>
            </CardBody>
        </>

    )
}

export { ConsumerTraining };