import React from "react";
import { BsFileText, BsFiletypeDocx } from 'react-icons/bs';
import { ResourceButton, ActionButton } from "ui-library";
import { AiOutlineFileExcel, AiFillVideoCamera, AiOutlineQuestionCircle, AiOutlineFileImage, AiOutlineFilePdf, AiFillIeCircle } from "react-icons/ai";

let IconButton = ({ item, iconSize = 28, cssClass = 'resourceIcon', subTitleSize = 0, icon }) => {
    var subTitle = item.SubTitle;
    if (subTitle != null && subTitle.length > subTitleSize) { subTitle = subTitleSize > 0 ? subTitle.substring(0, subTitleSize)+'...' : subTitle };

    var buttonIcon = icon ?? item.Extension;
    if (cssClass == 'resourceIcon') {
        return <ResourceButton title={item.Label} url={item.URL} reactIcon={<IconComponent className={cssClass} size={iconSize} extension={buttonIcon} />} subtitle={subTitle} />
    }
    return <ActionButton title={item.Label} url={item.URL} reactIcon={<IconComponent className={cssClass} size={iconSize} extension={buttonIcon} />} />
};

let IconComponent = (props) => {        // props: extension, className, size
    switch (props.extension) {
        case "pdf": return <AiOutlineFilePdf className={props.className} size={props.size} />
        case "mp4": case "videos": return <AiFillVideoCamera className={props.className} size={props.size} />
        case "xlsx": return <AiOutlineFileExcel className={props.className} size={props.size} />
        case "png": return <AiOutlineFileImage className={props.className} size={props.size} />
        case "txt": return <BsFileText className={props.className} size={props.size} />
        case "docx": return <BsFiletypeDocx className={props.className} size={props.size} />
        case "page": return <AiFillIeCircle className={props.className} size={props.size} />
        default: return <AiOutlineQuestionCircle className={props.className} size={props.size} />
    }
};

export { IconComponent, IconButton }