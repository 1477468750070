import React, { useState, useEffect } from "react";
import { format } from 'react-string-format';
import {
    CardTitle,
    CardBody,
    Accordion,
    AccordionSection
} from "ui-library";

import { AiOutlineArrowLeft } from "react-icons/ai";
import { goToResource, getQueryString } from '../../../Utils.js'


let APIDetails = () => {


    const [apiInfo, setApiInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    var api = getQueryString("api");


    var endPointId = getQueryString("endPoint");


    var aiSize = 22;


    const getAPI = () => {
        setLoading(true);
        console.log(api);
        let apiData = require(`../../../../../public/APIs/${api}.json`)
        console.log(apiData);
        setApiInfo(apiData);
        setLoading(false);
    };

    function goToEndPoint() {
        if (endPointId !== null)
            goToResource(endPointId);
    };

    useEffect(() => {
        getAPI();

        // Attach the callback after the component mounts
        window.addEventListener("load", goToEndPoint, true);

        // Detach the callback before the component unmounts
        return () => window.removeEventListener("load", goToEndPoint, true);


    }, []);

    var endpointID = 0;

    let apiSR = require('./ApiSampleResponses.json');

    return (
        <>
            <div style={{ textAlign: 'left', paddingLeft: '20px', paddingTop: '30px' }}>
                <a style={{ color: 'inherit', textDecoration: 'inherit' }} href="/Interconnections/DataAPIs/Documentation">
                    <AiOutlineArrowLeft color="#222222" size={aiSize} /><span style={{ position: 'relative', top: '2px' }}>&nbsp;Go back</span>
                </a>
            </div>
            {
                !loading ?

                    <div>
                        <CardTitle title={apiInfo.APIInfo.APIName} alignment="left" />
                        <CardBody alignment="left">
                            <p dangerouslySetInnerHTML={{ __html: apiInfo.APIInfo.APIDescription }} />

                            <div>
                                <Accordion>
                                    {apiInfo.APIInfo.Endpoints.map((endpoint) => {
                                        console.log(endpoint.EndpointDescription)
                                        var listType = endpoint.ResponseType == "2" ? "an array" : "a paged array";
                                        var returnStr = endpoint.ResponseType == "1" ? "Returns " : "Returns " + listType + " list of ";
                                        var objectStr = endpoint.ResponseType == "1" ? "object" : "objects";
                                        var targetUrl = "/Interconnections/DataAPIs/DataDictionary?api=" + endpoint.DataEntity;

                                        var apiSRindex = -1;
                                        var desiredID = apiInfo.APIInfo.APIID;
                                        apiSR.every((item) => {
                                            apiSRindex++;
                                            if (item.ApiId.toUpperCase().trim() === desiredID.toUpperCase().trim()) return false;
                                            return true;
                                        });
                                        var endPId = apiInfo.APIInfo.APIID + endpointID++;
                                        return (
                                            <div>
                                                <AccordionSection id={endPId} isExpanded={endPId == endPointId} title={endpoint.RelativeUrl} subtitle={endpoint.EndpointDescription} titleImageSource="/img/roundedRectangle.svg">
                                                    <div>
                                                        <h5><b>Parameters</b></h5>
                                                        <div>
                                                            <table className="usa-table usa-table--borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Type</th>
                                                                        <th scope="col">Definition</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {endpoint.RequestRouteParameters ? endpoint.RequestRouteParameters.map((field) => {
                                                                        return (<tr>
                                                                            <th scope="row">{field.Name}</th>
                                                                            <td>{field.Type}</td>
                                                                            <td>{field.DescriptionOverwrite ? field.DescriptionOverwrite : field.Description}</td>
                                                                        </tr>);
                                                                    }) : <></>}
                                                                    {endpoint.RequestQueryParameters ? endpoint.RequestQueryParameters.map((field) => {
                                                                        return (<tr>
                                                                            <th scope="row">{field.Name}</th>
                                                                            <td>{field.Type}</td>
                                                                            <td>{field.DescriptionOverwrite ? field.DescriptionOverwrite : field.Description}</td>
                                                                        </tr>);
                                                                    }) : <></>}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <h5><b>Request Format</b></h5>
                                                        <div className="apiDocGrayBackground">
                                                            {format("curl  \"https://developer.usastaffing.gov/api{0}\"  \\\r\n    -H  \"Api-Key: <API-KEY>\"", endpoint.RelativeUrl)}
                                                        </div>
                                                        <br />
                                                        <h5><b>Success Response</b></h5>
                                                        <b>HTTP Status: 200 OK</b>

                                                        <p>
                                                            {returnStr} <a href={targetUrl}>{endpoint.DataEntity}</a> {objectStr}
                                                        </p>

                                                        <p className="apiDocGrayBackground">
                                                            <pre>{apiSR[apiSRindex].ApiInfo.find((item) => item.RelativeUrl.trim() == endpoint.RelativeUrl.trim()).SampleResponse}</pre>
                                                        </p>
                                                        <br />
                                                        <h5><b>Response Codes</b></h5>
                                                        <div>
                                                            <table className="usa-table usa-table--borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Status</th>
                                                                        <th scope="col">Description</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row"><strong>204</strong></th>
                                                                        <td>No Content. No data is returned.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><strong>400</strong></th>
                                                                        <td>Bad Request. Request is malformed or incorrectly sent.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><strong>401</strong></th>
                                                                        <td>Unauthenticated. API Key not provided, is invalid, or has expired.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><strong>403</strong></th>
                                                                        <td>Forbidden response status code. Check that IP contacting the API is included on our white list.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><strong>429</strong></th>
                                                                        <td>Quota and/or Throttle Rate limit reached. Request denied.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row"><strong>500</strong></th>
                                                                        <td>Internal Server Error. Error has occurred on the server.</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionSection>
                                            </div>
                                        )
                                    })}
                                </Accordion>
                            </div>
                        </CardBody>
                    </div> :

                    <div></div>
            }
        </>
    )
}
export { APIDetails };