import React, { Component, useEffect } from 'react';
import { Container } from 'reactstrap';
import { SearchContainer } from './SearchContainer'
import { NavMenu } from './NavMenu';
import { Nav, NavLink, Container as OpmContainer, Row, Col } from 'ui-library'
import './NavMenu.css';
import { LayoutNavigation } from './LayoutNavigation';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {

        const returnToTop = () => {
            window.scrollTo(0, 0);
        };

        return (

            <div>

                <section
                    className="usa-banner"
                    aria-label="Official website of the United States government"
                >
                    <div className="usa-accordion">
                        <header className="usa-banner__header">
                            <div className="usa-banner__inner" style={{ maxWidth: '1366px', paddingLeft: '18px' }}>
                                <div className="grid-col-auto">
                                    <img
                                        aria-hidden="true"
                                        className="usa-banner__header-flag"
                                        src="/img/us_flag_small.png"
                                        alt=""
                                    />
                                </div>
                                <div className="grid-col-fill tablet:grid-col-auto" aria-hidden="true">
                                    <p className="usa-banner__header-text">
                                        An official website of the United States government
                                    </p>
                                    <p className="usa-banner__header-action">Here's how you know"</p>
                                </div>
                                <button
                                    type="button"
                                    className="usa-accordion__button usa-banner__button"
                                    aria-expanded="false"
                                    aria-controls="gov-banner-default-default"
                                >
                                    <span className="usa-banner__button-text">Here's how you know</span>
                                </button>
                            </div>
                        </header>
                        <div style={{ maxWidth: '1366px', paddingLeft: '18px' }}
                            className="usa-banner__content usa-accordion__content"
                            id="gov-banner-default-default"
                            hidden
                        >
                            <div className="grid-row grid-gap-lg">
                                <div className="usa-banner__guidance tablet:grid-col-6">
                                    <img
                                        className="usa-banner__icon usa-media-block__img"
                                        src="./img/dot_gov_icon.svg"
                                        role="img"
                                        alt=""
                                        aria-hidden="true"
                                    />
                                    <div className="usa-media-block__body">
                                        <p>
                                            <strong>Official websites use .gov</strong><br />A
                                            <strong> .gov</strong> website belongs to an official government <br />
                                            organization in the United States.
                                        </p>
                                    </div>
                                </div>
                                <div className="usa-banner__guidance tablet:grid-col-6">
                                    <img
                                        className="usa-banner__icon usa-media-block__img"
                                        src="./img/lock_icon.svg"
                                        role="img"
                                        alt=""
                                        aria-hidden="true"
                                    />
                                    <div className="usa-media-block__body">
                                        <p>
                                            <strong>Secure .gov websites use HTTPS</strong><br />A
                                            <strong> lock</strong> (
                                            <span className="icon-lock"
                                            ><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="52"
                                                height="64"
                                                viewBox="0 0 52 64"
                                                className="usa-banner__lock-image"
                                                role="img"
                                                aria-labelledby="banner-lock-description-default"
                                                focusable="false"
                                            >
                                                    <title id="banner-lock-title-default">Lock</title>
                                                    <desc id="banner-lock-description-default">Locked padlock icon</desc>
                                                    <path
                                                        fill="#000000"
                                                        fillRule="evenodd"
                                                        d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                                                    />
                                                </svg> </span
                                            >) or <strong>https://</strong> means you've safely connected to
                                            the .gov website. Share sensitive information only on official,
                                            secure websites.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div id="top" className="main-header">
                    <div className="opm-maxwidth">
                        <OpmContainer>
                            <Row>
                                <a href='./' style={{ textDecoration: "none" }}>
                                    <Col colClass='grid-col flex-auto'>
                                        <span className="staffing"> USA Staffing</span>&nbsp;&nbsp;<span className="data">DATA</span>
                                    </Col>
                                </a>

                                <Col colClass='grid-col'>
                                    <LayoutNavigation />
                                </Col>

                                <Col colClass='grid-col flex-auto'>
                                    <a href='https://www.opm.gov' target="_blank">
                                        <img className="opm-logo" alt='OPM logo' src="/img/logo_opm.svg" />
                                    </a>
                                </Col>
                            </Row>
                        </OpmContainer>
                    </div>

                </div>
                <div className="search-header">
                    <SearchContainer />
                </div>

                <Container style={{ maxWidth: '1366px' }} tag="main">
                    {this.props.children}
                </Container>

                <footer className="usa-footer" style={{ position: 'absolute', bottom: '0px', width: '100%' }} >
                    <div className="usa-footer__return-to-top">
                        <div className="opm-maxwidth" >
                            <a className="stylizedlink" onClick={returnToTop}>Return to top</a>
                        </div>
                    </div>

                    <div className="usa-footer__secondary-section">

                        <div className="opm-footer__img">

                            <img src="/logo_text_opm.svg" alt='OPM Text logo' />

                        </div>
                    </div>
                </footer>
            </div>



        );
    }
}
