import React from "react";
import {
    Card,
    CardAction,
    CardTitle,
    CardBody,
    TabletCol,
    Container, 
    Row
} from "ui-library";

let Cognos = () => {

    let cognos = require('./Cognos.json');
    const { OverviewTitle, OverviewText } = cognos[0];

    let ra = require('./Author/ReportAuthor.json');
    const { AuthorOverviewTitle, AuthorCardOverviewText, AuthorCardActionText } = ra[0];

    let rc = require('./Consumer/ReportConsumer.json');
    const { ConsumerOverviewTitle, ConsumerCardOverviewText, ConsumerCardActionText } = rc[0];

    let ja = require('./JobAids/JobAids.json');
    const { JobAidsOverviewTitle, JobAidsCardOverviewText, JobAidsCardActionText } = ja[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">

                <p>
                    {OverviewText}
                </p>
                <div>
                <TabletCol>
                    <Container>
                        <Row>
                            <TabletCol>
                                <Card autoWidth={false} alignment='left'>
                                    <CardTitle title={AuthorOverviewTitle} />
                                    <CardBody>
                                        {AuthorCardOverviewText}
                                    </CardBody>
                                    <CardAction title={AuthorCardActionText} url={'/Training/Cognos/ReportAuthor'} />
                                </Card>
                            </TabletCol>
                            <TabletCol>
                                <Card autoWidth={false} alignment='left'>
                                    <CardTitle title={ConsumerOverviewTitle} />
                                    <CardBody>
                                        {ConsumerCardOverviewText}
                                    </CardBody>
                                    <CardAction title={ConsumerCardActionText} url={'/Training/Cognos/ReportConsumer'} />
                                </Card>
                            </TabletCol>
                            <TabletCol>
                                <Card autoWidth={false} alignment='left'>
                                    <CardTitle title={JobAidsOverviewTitle} />
                                    <CardBody>
                                        {JobAidsCardOverviewText}
                                    </CardBody>
                                    <CardAction title={JobAidsCardActionText} url={'/Training/Cognos/JobAids'} />
                                </Card>
                            </TabletCol>
                        </Row>
                    </Container>
                </TabletCol>
                </div>

            </CardBody>
        </>

    )
}

export { Cognos };