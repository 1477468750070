import React from "react";
import {
    Card,
    CardAction,
    CardTitle,
    CardBody,
    TabletCol,
    Container,
    Row
} from "ui-library";
import { IconButton } from '../../IconComponent';


let PowerBI = () => {

    let powerbi = require('./PowerBI.json');
    const { OverviewTitle, OverviewText, PowerBILinks } = powerbi[0];

    let rc = require('./Consumer/ReportConsumer.json');
    const { ConsumerOverviewTitle, ConsumerOverviewText, ConsumerCardActionText, NavboxText } = rc[0];

    let ja = require('./JobAids.json');
    const { JobAidsOverviewTitle, JobAidsCardOverviewText, JobAidsCardActionText } = ja[0];

    return (
        <>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">

                <p>{OverviewText}</p>

                <div>
                    <Container>
                        <Row>
                            <TabletCol>
                                <Card >
                                    <CardTitle title={ConsumerOverviewTitle} />
                                    <CardBody>
                                        {NavboxText}
                                    </CardBody>
                                    <CardAction title={ConsumerCardActionText} url={'/Training/PowerBI/ReportConsumer'} />
                                </Card>
                            </TabletCol>
                            <TabletCol>
                                <Card >
                                    <CardTitle title={JobAidsOverviewTitle} />
                                    <CardBody>
                                        {JobAidsCardOverviewText}
                                    </CardBody>
                                    <CardAction title={JobAidsCardActionText} url={'/Training/PowerBI/JobAids'} />
                                </Card>
                            </TabletCol>
                            <TabletCol>
                            </TabletCol>
                        </Row>
                    </Container>
                </div>
            </CardBody>
        </>

    )
}

export { PowerBI };